import React from 'react';
import { connect, getIn } from 'formik';
import { FormText } from 'reactstrap';

interface SubmissionConfirmationProps {
  message: string | React.ReactNode
}
 
const SubmissionConfirmation: React.SFC<SubmissionConfirmationProps> = ({message}) => {
  return (  
    <FormText color="secondary" className="form__messages">
      <strong>Success: </strong> {message}
    </FormText>
  );
};

interface SubmissionErrorProps {
  message: string | React.ReactNode
}
 
const SubmissionError: React.SFC<SubmissionErrorProps> = ({message}) => {
  return (  
    <FormText color="danger" className="form__messages">
      <strong>Error: </strong> {message}
    </FormText>
  );
};

const FormSubmissionMessage = (props: any) => {
  const status = getIn(props.formik, 'status');
  const success = getIn(props.formik.status, 'success');
  const failure = getIn(props.formik.status, 'failure');

  if (status) {
    return success ? <SubmissionConfirmation message={success} /> : <SubmissionError message={failure} />;
  }

  return null;
};

export default connect(FormSubmissionMessage);