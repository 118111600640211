import React from 'react';
import { FormText } from 'reactstrap';

export interface FormFieldHelptextProps {
  text: React.ReactNode,
  a11y?: React.ReactNode
}
 
const FormFieldHelptext: React.SFC<FormFieldHelptextProps> = ({text, a11y}) => {
  return (
    <>
      <FormText color="white" className="field__help-text">
      	{text}
      </FormText>

      { a11y && 
        <FormText className="field__a11y-text sr-only">
        	{a11y}
        </FormText>
      }
    </>
  );
};
 
export default FormFieldHelptext;