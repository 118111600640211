import * as React from 'react';
import { FormGroup, Alert } from 'reactstrap';

const FormMessagesGroup: React.FunctionComponent<{message: string; messageType: any}> = ({ message, messageType }) => {
  const type = (messageType === 'error') ? 'danger' : 'info';
  return (
    <FormGroup className="form-group--form-messages">
      <Alert color={type} className="form__messages">
        <strong>{messageType === 'error' ? 'Error' : 'Notice'} </strong>
        {message}
      </Alert>
    </FormGroup>
  );
};

export default FormMessagesGroup;
