export type ClientSetting = {
  clientId: string,
  clientSecret: string,
  domain: string,
  callback?: string
}

const requiredEnv = {
  'REACT_APP_OAPI_BASE_URL': process.env.REACT_APP_OAPI_BASE_URL,
  'REACT_APP_OAPI_CLIENT_ID': process.env.REACT_APP_OAPI_CLIENT_ID,
  'REACT_APP_OAPI_CLIENT_SECRET': process.env.REACT_APP_OAPI_CLIENT_SECRET,
  'REACT_APP_AUTH_PORTAL_URL': process.env.REACT_APP_AUTH_PORTAL_URL,
  'REACT_APP_PORTAL_MHV_CLIENT_ID': process.env.REACT_APP_PORTAL_MHV_CLIENT_ID,
  'REACT_APP_PORTAL_MHV_CLIENT_SECRET': process.env.REACT_APP_PORTAL_MHV_CLIENT_SECRET,
  'REACT_APP_PORTAL_MHV_CLIENT_DOMAIN': process.env.REACT_APP_PORTAL_MHV_CLIENT_DOMAIN,
  'REACT_APP_PORTAL_MHV_CLIENT_CALLBACK': process.env.REACT_APP_PORTAL_MHV_CLIENT_CALLBACK,
  'REACT_APP_PORTAL_NVSM_CLIENT_ID': process.env.REACT_APP_PORTAL_NVSM_CLIENT_ID,
  'REACT_APP_PORTAL_NVSM_CLIENT_SECRET': process.env.REACT_APP_PORTAL_NVSM_CLIENT_SECRET,
  'REACT_APP_PORTAL_NVSM_CLIENT_DOMAIN': process.env.REACT_APP_PORTAL_NVSM_CLIENT_DOMAIN,
  'REACT_APP_PORTAL_NVSM_CLIENT_CALLBACK': process.env.REACT_APP_PORTAL_NVSM_CLIENT_CALLBACK,
  'REACT_APP_PORTAL_FRAUD_CLIENT_ID': process.env.REACT_APP_PORTAL_FRAUD_CLIENT_ID,
  'REACT_APP_PORTAL_FRAUD_CLIENT_SECRET': process.env.REACT_APP_PORTAL_FRAUD_CLIENT_SECRET,
  'REACT_APP_PORTAL_FRAUD_CLIENT_DOMAIN': process.env.REACT_APP_PORTAL_FRAUD_CLIENT_DOMAIN,
  'REACT_APP_PORTAL_FRAUD_CLIENT_CALLBACK': process.env.REACT_APP_PORTAL_FRAUD_CLIENT_CALLBACK,
};

const [
  oapiBaseUrl,
  oapiClientId,
  oapiClientSecret,
  authPortalUrl,
  portalMHVClientId,
  portalMHVClientSecret,
  portalMHVClientDomain,
  portalMHVClientCallback,
  portalNVSMClientId,
  portalNVSMClientSecret,
  portalNVSMClientDomain,
  portalNVSMClientCallback,
  portalFraudClientId,
  portalFraudClientSecret,
  portalFraudClientDomain,
  portalFraudClientCallback,
]: string[] = Object.entries(requiredEnv)
  .map(([ key, value ]) => {
    if (!value) {
      throw new ReferenceError(`Required envvar of ${key} is undefined`);
    }

    return value;
  });

const authPortalClients: ClientSetting[] = [
  {
    clientId: portalMHVClientId,
    clientSecret: portalMHVClientSecret,
    domain: portalMHVClientDomain,
    callback: portalMHVClientCallback,
  },
  {
    clientId: portalNVSMClientId,
    clientSecret: portalNVSMClientSecret,
    domain: portalNVSMClientDomain,
    callback: portalNVSMClientCallback,
  },
  {
    clientId: portalFraudClientId,
    clientSecret: portalFraudClientSecret,
    domain: portalFraudClientDomain,
    callback: portalFraudClientCallback,
  },
];

export const config = {
  oapiBaseUrl,
  oapiClientId,
  oapiClientSecret,
  authPortalUrl,
  authPortalClients,
};

export default config;
