import * as React from 'react';
import { RecoveryForm } from '../../Forms/RecoveryForm';
import { RouteProps } from 'react-router';

export interface RecoveryPageProps {}
 
const RecoveryPage: React.FunctionComponent<RecoveryPageProps & RouteProps> = (props) => {
  return ( 
    <RecoveryForm title="Account Recovery" handleFormSubmit={() => console.log('Hello')} {...props}/>
  );
};
 
export default RecoveryPage;