import React from 'react';
import { connect, getIn } from 'formik';
import { FormText } from 'reactstrap';

interface FormFieldErrorMessageProps {
  message: string
}
 
const FormFieldErrorMessage: React.SFC<FormFieldErrorMessageProps> = ({message}) => {
  return (
    <FormText color="danger" className="field__errors">
      <strong>Error:</strong> {message}
    </FormText>
  );
};

const FormFieldError = (props: any) => {
  const error = getIn(props.formik.errors, props.name);
  const touch = getIn(props.formik.touched, props.name);
  return touch && error ? <FormFieldErrorMessage message={error} /> : null;
};

export default connect(FormFieldError);