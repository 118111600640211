import * as React from 'react';
 
export const Logo: React.SFC<{}> = () => {
  return (
    <svg version="1.1" viewBox="0 0 383.6 361.8">
      <title>nexVortex</title>
      <g>
        <path d="M201.2,289c-41.2,0-76.8-27.9-86.8-68.2l13.6-3.4c4.2,17,13.5,31.5,27,41.9c13.5,10.4,29.7,15.9,47,15.6
            c22.4-0.3,45.2-11.4,62.6-30.7c18.7-20.6,28.6-48,26.5-73.1c-3-35.4-18.5-65.5-44.6-87c-25-20.6-57.7-31.1-92.1-29.6
            c-39.4,1.8-75.8,20.9-102.5,54c-14.6,18-25.6,39.6-31.9,62.3c-6.4,23-7.7,46.7-3.9,68.6l-13.8,2.4C-6.1,193.2,8.4,140.1,41,99.7
            c29.3-36.3,69.3-57.2,112.9-59.1c37.9-1.6,73.9,10.1,101.6,32.8c28.7,23.6,46.3,57.9,49.7,96.6c2.5,29.4-8.4,59.9-30,83.7
            c-20.1,22.1-46.6,35-72.8,35.2C201.9,289,201.6,289,201.2,289z"/>
      </g>
      <g>
        <path d="M202.4,361.8c-21.1,0-41.9-3.8-61.1-11c-25.1-9.6-47.1-24.9-65.4-45.5c-24.7-27.9-36.5-69.4-31.7-111.1
            c4.6-39.7,23.2-73.7,50.9-93.2c21-14.8,47.9-22.9,73.7-22c27.3,0.9,50.9,11.3,66.5,29.2l-10.6,9.2c-13-15-33.1-23.6-56.4-24.4
            c-22.8-0.7-46.6,6.3-65.2,19.5c-24.5,17.3-40.9,47.7-45,83.4C53.8,233.6,64.4,271,86.4,296c16.7,18.9,36.9,32.9,59.9,41.7
            c21.2,8.1,44.7,11.4,68.1,9.7c50.2-3.7,95.5-29.3,121-68.5c32.1-49.3,41.7-101,28.6-153.6C351.6,75.6,321.9,36,299.1,11.5L309.4,2
            c23.9,25.7,55.1,67.4,68.2,120c14.1,56.6,3.8,111.9-30.5,164.6c-27.9,42.8-77.1,70.8-131.7,74.9
            C211.1,361.6,206.7,361.8,202.4,361.8z"/>
      </g>
      <g>
        <path d="M200.5,326.2c-27,0-53.6-8.2-75.3-23.8c-27.9-20-44.2-48.9-46-81.5c-1.4-25,3.8-48,14.9-66.6
            c11.6-19.5,29.3-33.1,51.2-39.5l3.9,13.4c-37,10.9-58.5,46.1-56,91.9c1.5,28.2,15.8,53.4,40.1,70.8c27.9,20,64.9,26.3,99,16.8
            c41.6-11.6,73.1-40.8,88.8-82.3c16.1-42.3,12.6-91.1-8.8-124.2c-26.4-40.9-60.8-67.8-102.1-80c-42.3-12.5-83.3-6.9-110.2,0
            L96.5,7.8C137.6-2.6,178.3-2.6,214.1,8c44.7,13.2,81.6,42,109.9,85.8c23.7,36.7,27.7,90.4,10.1,136.8c-17.3,45.7-52.2,78-98.1,90.8
            C224.4,324.6,212.4,326.2,200.5,326.2z"/>
      </g>
    </svg>
  );
};