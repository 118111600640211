import { AxiosInstance } from 'axios';
import { ApiResponse, IPAddress, Port, DateTime } from './types';

export interface Path {
	/** If cost > 5 then connection is LTE, else connection is Wired */
	cost: number,
	senderInterface: string,
	senderIP: IPAddress,
	receiverInterface: string,
	receiverIP: IPAddress,
	receiverPort: Port,
	lossRatio: number,
	packetsSent: number,
	packetsReceived: number,
	originalPacketsRecieved: number,
	dataReceived: number,
	packetsLost: number,
	packetsRate: number,
	timeInterval: number,
	roundTripTime: number,
	active: boolean,
	other: any[]
}

export interface QualityReport {
	escalationCost: number,
	lossRatio: number,
	packetsSent: number,
	packetsReceived: number,
	originalPacketsRecieved: number,
	dataReceived: number,
	packetsLost: number,
	packetsRate: number,
	timeInterval: number,
	roundTripTime: number,
	links: Path[],
	other: any[]
}

export interface EdgeDeviceState {
	nvsbTunnelRecid: number,
	createDateTime: DateTime,
	updateDateTime: DateTime,
	eventTimestamp: DateTime,
	status: string, // 'active' | ?
	serverPacketsSent: number,
	serverPacketsReceived: number,
	clientPacketsSent: number,
	clientPacketsReceived: number,
	numberOfCalls: number,
	fullReport: {
		cpuId: string,
		nvsbTunnelRecId: number,
		sdngwNodeName: string,
		sdngwHostName: string,
		timestamp: DateTime,
		activeCalls: number,
		packing: boolean,
		escalationCost: number,
		maxPathCost: number,
		icmpPings: any[],
		other: any[],
		paths: {
			nVEInterface: string,
			sdngwInterface: string,
			nVEIP: IPAddress,
			sdngwIP: IPAddress,
			nVEPort: Port,
			sdngwPort: Port
		}[],
		gatewayQuality: QualityReport,
		clientQuality: QualityReport
	}
}

export interface EdgeDeviceTunnel {
	nvsbTunnelRecid: number,
	createDateTime: string,
	updateDateTime: string,
	status: string,
	nvsbSdngwRecid: number,
	nvsbDeviceRecid: number,
	tunnelId: number,
	tunnelAddress: number,
	kIpAddress: IPAddress,
	cIntName: string,
	dIntName: string,
	serverTunnelHeaderVsn: number,
	qualityLogInterval: number,
	logToFile: number,
	commandHeaderVsn: number,
	pathConfigVersion: number
}

export interface UpdatePathConfigPayload {
	action: 'update_path_configuration',
	parameters: {
		pathConfigId: number
	}
}

export interface InjectTunnelPacketLossPayload {
	action: 'inject_tunnel_packet_loss',
	parameters: {
		sdngwIntName: string,
		nVEIntName: string,
		loss: number // ratio, 0. - 1.
	}
}

export interface EdgeDeviceHealth {
	id: string,
	count: number,
	start: number,
	data: {
		time: number,
		status: string,
		message: string,
		lte: {
			tags: string[],
			override: boolean,
			id: string,
			address: IPAddress
		}[],
		firmware: string,
		destination: IPAddress,
		addresses: IPAddress[]
	}
}

export class EdgeDevice {
	private http: AxiosInstance;
	public constructor(http: AxiosInstance) {
	  this.http = http;
	}

	/**
	 * Get device quality report
	 * 
	 * @param id Edge device CPU ID
	 */
	async get(id: string) {
	  if (!id || !(id.length > 0)) {
	    throw new TypeError('EdgeDevice CPU ID is required');
	  }

	  try {
	    const { data } = await this.http
	      .get<ApiResponse<EdgeDeviceState>>(`/v2/mHV/Inventory/EdgeDevices/${id}/Quality/State`);
	    return data;
	  }
	  catch (error) {
	    throw error;
	  }
	}

	/**
	 * Get device tunnel report
	 * 
	 * @param id Edge device CPU ID
	 */
	async tunnel(id: string) {
	  if (!id || !(id.length > 0)) {
	    throw new TypeError('EdgeDevice CPU ID is required');
	  }

	  try {
	    const { data } = await this.http
	      .get<ApiResponse<EdgeDeviceTunnel>>(`/v2/mHV/Inventory/EdgeDevices/${id}/Tunnel`);
	    return data;
	  }
	  catch (error) {
	    throw error;
	  }
	}

	/**
	 * Update the device path configuration
	 * 
	 * @param id Edge device CPU ID
	 * @param configId Edge device pathyway configuration ID
	 */
	public async updatePathConfiguration(id: string, configId: number) {
	  if (!id || !(id.length > 0)) {
	    throw new TypeError('EdgeDevice CPU ID is required');
	  }

	  if (!configId) {
	    throw new TypeError('EdgeDevice Configuration ID is required');
	  }

	  const config: UpdatePathConfigPayload = {
	    action: 'update_path_configuration',
	    parameters: {
	      pathConfigId: configId
	    }
	  };

	  try {
	    // Response body is a string
	    const { data } = await this.http.post<string>(
	      `/v2/mHV/Inventory/EdgeDevices/${id}/Actions`,
	      { data: config }
	    );
	    return data;
	  }
	  catch (error) {
	    throw error;
	  }
	}

	/**
	 * Inject simulated path packet loss for the device
	 *
	 * Returns a string as the response body on successful requests, not JSON.
	 *
	 * @param id Edge device CPU ID
	 * @param gatewayName Name of SDN gateway target interface
	 * @param interfaceName Name of edge device target interface
	 * @param ratio Packet loss ratio to inject, `0.` - `1.`
	 */
	public async injectTunnelPacketLoss(id: string, gatewayName: string, interfaceName: string, ratio: number) {
	  if (!id || !(id.length > 0)) {
	    throw new TypeError('EdgeDevice CPU ID is required');
	  }

	  if (!gatewayName) { throw new TypeError('EdgeDevice Gateway Name is required'); }
	  if (!interfaceName) { throw new TypeError('EdgeDevice Interface Name is required'); }
	  if (!ratio) { throw new TypeError('EdgeDevice packet loss ratio is required'); }
	
	  const config: InjectTunnelPacketLossPayload = {
	    action: 'inject_tunnel_packet_loss',
	    parameters: {
	      sdngwIntName: gatewayName,
	      nVEIntName: interfaceName,
	      loss: ratio
	    }
	  };

	  try {
	    // Response body is a string
	    const { data } = await this.http.post<string>(
	      `/v2/mHV/Inventory/EdgeDevices/${id}/Actions`,
	      { data: config }
	    );
	    return data;
	  }
	  catch (error) {
	    throw error;
	  }
	}

	/** Check EMS health via ping */
	public async health(id: string) {
	  if (!id || !(id.length > 0)) {
	    throw new TypeError('EdgeDevice CPU ID is required');
	  }

	  try {
	    const { data } = await this.http
	      .post<EdgeDeviceHealth>(`/Assets/nVE/${id}/actions/healthcheck`);
	    return data;
	  }
	  catch (error) {
	    throw error;
	  }
	}
}

export default EdgeDevice;
