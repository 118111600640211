import * as React from 'react';
import { Field, FieldProps } from 'formik';
import { FormGroup, Label, Input, } from 'reactstrap';

// Import local Components
import { FormFieldError, FormFieldHelptext } from 'src/components/shared/Form';

const FirstNameFieldGroup: React.SFC<{}> = () => (
  <FormGroup className="form-group--email-address">
    <Field
      type="text"
      name="firstName"
      id="firstName"
      render={({ field , form}: FieldProps) => {
        return (
          <>
            <Label for={field.name}>{field.name}</Label>

            <FormFieldError name={field.name} />

            <Input
            	type="text"
            	bsSize="lg"
            	invalid={!!(form.touched[field.name] && form.errors[field.name])}
            	{...field}
            />
            
            <FormFieldHelptext
            	text="Enter your first name."
            	a11y="Please enter your full first name to be used in creating your support ticket"
            />
          </>
        );
      }}
    />
  </FormGroup>
);

export default FirstNameFieldGroup;
