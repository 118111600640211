import * as Yup from 'yup';
import { withFormik, FormikBag } from 'formik';

// Import local Components.
import InnerRecoveryForm from './InnerForms';

// Import local Assets.
import './styles.scss';

// Defines Form values shape.
export interface RecoveryFormValues {
  email: string
}

// Defines Form props shape.
export interface RecoveryFormProps {
  title?: string | React.ElementType,
  message?: string,
  initialEmail?: string,
  handleFormSubmit: () => void
}

// Defines the wrapped RecoveryForm Formik component.
export const RecoveryForm = withFormik<RecoveryFormProps, RecoveryFormValues>({
  // Transforms outer props into form values.
  mapPropsToValues: props => {
    return {
      email: props.initialEmail || '',
    };
  },

  // Validates form values using Yup validation schema.
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('E-mail address is not a valid format.')
      .required('E-mail address is required'),
  }),

  // Processes submit behavior.
  handleSubmit: (values: RecoveryFormValues, formik: FormikBag<RecoveryFormProps, RecoveryFormValues>) => {
    setTimeout(() => {
      formik.props.handleFormSubmit();
      formik.setSubmitting(false);
    }, 500);
  }
})(InnerRecoveryForm);
