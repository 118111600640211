import * as React from 'react';
import * as Yup from 'yup';
import { withFormik, FormikActions, FormikBag } from 'formik';

// Import local Components.
import InnerLoginForm from 'src/components/features/Forms/LoginForm/InnerForms/InnerLoginForm';

// Import local Assets.
import './styles.scss';

// Defines the shape of the form values.
export interface LoginFormValues {
  email: string,
  password: string
}

// Defines the shape of the props available for the component.
export interface LoginFormProps {
  title?: string | React.ElementType,
  message?: string,
  messageType?: string,
  initialPropValues?: Partial<LoginFormValues>,
  handleLoginSubmit: (state: LoginFormValues) => void
}

// Wraps the LoginForm component using the withFormik HOC.
export const LoginForm = withFormik<LoginFormProps, LoginFormValues>({
  // Enable reinitialize from prop changes.
  enableReinitialize: true,

  // Transforms outer props into form values.
  mapPropsToValues: ({initialPropValues}) => {
    if (initialPropValues) {
      return {
        email: initialPropValues.email ? initialPropValues.email : '',
        password: '',
      };
    }

    return {
      email: '',
      password: '',
    };
  },

  // Validates form values using Yup validation schema.
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('E-mail address is not a valid format.')
      .required('E-mail address is required'),
    password: Yup.string()
      .min(6, 'Password has to be longer than 6 characters')
      .required('Password is required'),
  }),

  // Processes submit behavior.
  handleSubmit: (values: LoginFormValues, formikBag: FormikActions<LoginFormValues> & FormikBag<LoginFormProps, LoginFormValues>) => {
    const { handleLoginSubmit } = formikBag.props;
    handleLoginSubmit(values);
    formikBag.setSubmitting(false);
  }
})(InnerLoginForm);
