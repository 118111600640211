import * as React from 'react';
import { Omit } from 'ts-essentials';
import { Form, FormikProps } from 'formik';
import { Button, FormGroup, Spinner } from 'reactstrap';

// Import local Components.
import { LoginFormValues, LoginFormProps } from 'src/components/features/Forms/LoginForm';
import { EmailAddressFieldGroup } from 'src/components/shared/Form';
import { FormMessagesGroup, PasswordGroup, HiddenFieldsGroup, LoginSupportGroup } from 'src/components/features/Forms/LoginForm/Fields';

// Defines the inner form to be wrapped by the Formik HOC.
const InnerLoginForm = (
  props: Omit<LoginFormProps, 'handleLoginSubmit'> & FormikProps<LoginFormValues>
) => {
  const { isSubmitting, message, messageType, title } = props;
  
  return (
    <Form className="form form--login-form">
      {title && <h3 className="form__title">{title}</h3>}

      { message && (
        <FormMessagesGroup message={message} messageType={messageType ? messageType : 'info'} />
      )}

      <EmailAddressFieldGroup />
      <PasswordGroup />

      <FormGroup className="form-group--submit-handlers">
        <Button type="submit" color="primary" size="lg" block={true} active={isSubmitting}>
          { isSubmitting ? <Spinner color="white" size="sm" /> : 'Sign In' }
        </Button>
      </FormGroup>

      <LoginSupportGroup />

    </Form>
  );
};


export default InnerLoginForm;
