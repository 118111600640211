import * as React from 'react';
import psl from 'psl';
import qs from 'query-string';
import { RouteComponentProps } from 'react-router';
import config from 'src/config.exports';

// Import local components.
import { LoginForm, LoginFormValues } from '../../Forms/LoginForm';
import { OApiContext } from 'src/react-oapi/OApiContext';
import { reducer } from './reducer';

// Defines exportable types.
export interface LoginPageProps {}

export type ReferrerOpts = {
  redirectURI: string,
  clientID: string,
  email?: string
}

export interface LoginPageState {
  notification?: {
    type: string,
    message: string
  },
  referrer?: ReferrerOpts
}

// Defines the routable page component.
const LoginPage: React.SFC<LoginPageProps & RouteComponentProps> = (props) => {

  // Defines local state management.
  const [ state, dispatch ] = React.useReducer(reducer, {});

  // Defines local contexts.
  const oapi = React.useContext(OApiContext);

  // Retrieves the client settings for a given redirectURI value.
  const parseClientSettings = (redirectURI: string) => {
    const { authPortalClients: clientSettings } = config;
    const domain = redirectURI.split('/');
    const setting = clientSettings.find(x => x.domain == domain[2]);
    
    if (!setting) throw Error('The provided referrer URI is not supported.');
    return setting;
  };

  // Defines login behavior for referrer services.
  const handleReferrerLogin = async (redirectURI: string, formState: LoginFormValues) => {
    try {
      if (oapi === null) throw Error('Unable to access the OAPI Client');

      const { email, password } = formState;
      const { clientId, clientSecret} = parseClientSettings(redirectURI);
      const token = await oapi.auth.authenticate(email, password, clientId, clientSecret);

      // Redirects back to referrer service.
      dispatch({type: 'SET_FORM_SUCCESS', value: 'User authenticated successfully. Redirecting you back...'});
      setTimeout(() => {  
        window.location.assign(`${redirectURI}?${
          qs.stringify(token, { encode: true })
        }`);
      }, 1000);
    } catch (error) {
      dispatch({type: 'SET_FORM_ERROR', value: error.message});
      throw Error(error.message);
    }
  };

  // Defines login behavior for non-referrer services.
  const handleNonReferrerLogin = async (formState: LoginFormValues) => {
    try {
      if (oapi === null) throw Error('Unable to access the OAPI Client');

      const { email, password } = formState;
      const token = await oapi.auth.authenticate(email, password);

      // Redirect authenticated user to service overview page.
      dispatch({type: 'SET_FORM_SUCCESS', value: 'User authenticated successfully.'});
    } catch (error) {
      console.error(error.message);
      dispatch({type: 'SET_FORM_ERROR', value: error.message});
    }
  };

  // Defines login form submission callback behavior.
  const handleLogin = async (formState: LoginFormValues) => {
    try { 
      if (state.referrer && state.referrer.redirectURI) {
        await handleReferrerLogin(state.referrer.redirectURI, formState);
      }

      handleNonReferrerLogin(formState); 
    } catch (error) {
      console.error(error.message);
    }
  };

  // Defines querystring parser for referrer arguments.
  const parseQuerystringReferrals = () => {
    const { location } = props;
    const { redirect_uri: redirectURI, ...rest} = qs.parse(location.search, {decode: true});
    dispatch({type: 'SET_REFERRER_OPTS', value: { redirectURI, ...rest}});
  };

  // Defines component lifecycle events.
  React.useEffect(() => {
    parseQuerystringReferrals();
  }, []);

  return (
    <LoginForm 
      title="Account Sign In"
      message={state.notification ? state.notification.message : undefined}
      messageType={state.notification ? state.notification.type : undefined}
      initialPropValues={{
        email: (state.referrer && state.referrer.email) ? state.referrer.email : '',
      }}
      handleLoginSubmit={state => handleLogin(state)}
      {...props}
    />
  );
};
 
export default LoginPage;