import * as React from 'react';

// Import local Components.
import { ErrorPage, ErrorPageProps } from 'src/components/shared/Page';

export const PageAccessDenied: React.SFC<ErrorPageProps> = (props) => {
  return (
    <ErrorPage
      title="Access Denied (403)"
      summary="Access to page that you are attempting to request has been denied."
      className="page--access-denied"
      {...props}
    />
  );
};
 
export default PageAccessDenied;