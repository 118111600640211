import * as React from 'react';
import { FormGroup, FormText } from 'reactstrap';

const FormMessagesGroup: React.FunctionComponent<{message: string}> = ({ message }) => {
  return (
    <FormGroup className="form-group--form-messages">
      <FormText color="body" className="form__messages">
        <strong>Notice: </strong>
        {message}
      </FormText>
    </FormGroup>
  );
};

export default FormMessagesGroup;
