import * as React from 'react';
import { Redirect, Route, Switch, RouteProps } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

// Import local Components.
import { Logo } from 'src/components/shared/UI-System/Elements';
import { RecoveryForm } from 'src/components/features/Forms/RecoveryForm';
import { SupportForm } from './components/features/Forms/SupportForm';
import LoginPage from './components/features/Page/LoginPage';


//  Import local Assets.
import './App.scss';
import { PageNotFound, PageAccessDenied } from 'src/components/features/Page/ErrorPages';
import RecoveryPage from './components/features/Page/RecoveryPage';

// Defines App.tsx Component.
const App: React.FunctionComponent<{}> = () => {

  // Defines local state management.
  // TODO: Remove Feature Flag API ideation into Context.
  const [state, dispatch] = React.useReducer((state: any, action: any)=>{
    return state;
  }, {
    featureFlags: {
      useNewBranding: false
    }
  });

  return (  
    <div className="App">
      <header className="App-header">
        <h1 className="sr-only">
          nexVortex Authentication and Authorization Portal
        </h1>
        <Logo forwardFeature={state.featureFlags.useNewBranding} />
      </header>
      <BrowserRouter>
        <Switch>

          {/* TODO: Replace in React Router 4.3.1-rc.1 release */}
          <Route 
            path="/"
            exact={true}
            component={({location}: RouteProps) => (
              <Redirect
                to={{
                  ...location,
                  pathname: "/insecure/account/login"
                }}
              />
            )}
          />

          <Route
            path="/insecure/account/login"
            exact={false}
            render={(props: any) => (
              <LoginPage {...props} />
            )}
          />

          <Route
            path="/insecure/account/recovery"
            exact={false}
            render={(props: any) => <RecoveryPage {...props} />}
          />

          <Route
            path="/insecure/account/support"
            exact={false}
            render={(props: any) => <SupportForm title="Account Support" {...props} />}
          />

          <Route 
            path="/error/403"
            exact={true}
            render={(props: any) => <PageAccessDenied />}
          />

          <Route 
            path="/error/404"
            exact={true}
            render={(props: any) => <PageNotFound />}
          />
          <Route render={(props: any) => <Redirect to="/error/404" {...props} />} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

// Exports named default.
export default App;
