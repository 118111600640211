import { Context, WebExtension } from '@nexvortex/framework';
import * as React from 'react';

import OApiClient from '../oapi';
import { OApiProvider } from '../react-oapi/OApiContext';

export class ReactOApiExtension implements WebExtension {
	private oapi: OApiClient | null = null;

	public provides = ['react-oapi'];
	public uses = [];
	public needs = ['oapi', 'react'];
	public excludes = [];
	public first = true;
	public last = false;

	public prepare(context: Context) {
	  const oapi = (context as Context<OApiClient>).get('oapi');
	  if (!oapi) { throw new ReferenceError('OAPI client was not found'); }

	  this.oapi = oapi;
	}

	public run(context: Context<React.ReactElement<any>>) {
	  const oapi = this.oapi;
	  if (!oapi) { throw new ReferenceError('OAPI client was not found'); }

	  const tree = context.get('react');
	  if (!tree) { throw new ReferenceError('React component tree is not defined'); }

	  context.set('react', (
	    <OApiProvider value={oapi}>
	      {tree}
	    </OApiProvider>
	  ));
	}
}

export default ReactOApiExtension;
