import * as React from 'react';
import { Field, FieldProps } from 'formik';
import { FormGroup, Label, Input, } from 'reactstrap';

// Import local Components
import { FormFieldError, FormFieldHelptext } from 'src/components/shared/Form';

const EmailAddressFieldGroup: React.SFC<{}> = () => (
  <FormGroup className="form-group--email-address">
    <Field
      type="email"
      name="email"
      id="email"
      render={({ field , form}: FieldProps) => {
        return (
          <>
            <Label for={field.name}>{field.name}</Label>

            <FormFieldError name={field.name} />

            <Input
            	type="email"
            	bsSize="lg"
            	invalid={!!(form.touched[field.name] && form.errors[field.name])}
            	{...field}
            />
            
            <FormFieldHelptext
            	text="Enter your email address."
            	a11y="Please enter your full email address to be used as your login
              username."
            />
          </>
        );
      }}
    />
  </FormGroup>
);

export default EmailAddressFieldGroup;
