import * as React from 'react';

// Import local Components.
import { ErrorPage, ErrorPageProps } from 'src/components/shared/Page';
 
export const PageNotFound: React.SFC<ErrorPageProps> = (props) => {
  return (
    <ErrorPage
      title="Page Not Found (404)"
      summary="The page you are attempting to access could not be found."
      className="page--not-found"
      {...props}
    />
  );
};
 
export default PageNotFound;