import * as React from 'react';
import { 
  Logo as LogoShape,
  LegacyLogo as LegacyLogoShape 
}  from 'src/components/shared/UI-System/Shapes';

export interface LogoProps {
  forwardFeature?: boolean,
  height?: string,
  width?: string
}

export const Logo: React.SFC<LogoProps & React.HTMLAttributes<HTMLDivElement>> = ({ className, forwardFeature, height, width, ...props }) => {
  const classNames = [
    'branding__logo',
    (!forwardFeature) ? 'logo--legacy': '',
    className
  ].join(' ');

  return (
    <div className={classNames} {...props}>
      { forwardFeature ? <LogoShape /> : <LegacyLogoShape />}
    </div>
  );
};