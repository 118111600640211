import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FormGroup, FormText, Nav, NavItem, NavLink } from 'reactstrap';
import { connect } from 'formik';
 
const LoginSupportGroup = (props: any) => {
  return ( 
    <FormGroup className="form-group--login-support">
      <FormText color="white">
        Not seeing what you need? Try these:
        <Nav vertical={true} className="nav--login-support">
          <NavItem>
            <NavLink tag={RouterLink} to="/insecure/account/recovery">Forgot your password?</NavLink>
          </NavItem>
          {/* Temporarily disabled until registration is supported.
          <NavItem>
            <NavLink tag={RouterLink} to="/insecure/account/registration">Register a new account</NavLink>
          </NavItem>
          */}
          <NavItem>
            <NavLink tag={RouterLink} to="/insecure/account/support">Contact a support representative</NavLink>
          </NavItem>
        </Nav>
      </FormText>
    </FormGroup>
  );
};
 
export default connect(LoginSupportGroup);