import * as Yup from 'yup';
import { withFormik, Formik, FormikActions, FormikProps } from 'formik';

// Import local Components.
import { InnerSupportForm } from './InnerForms';

// Import local Assets.
import './styles.scss';

// Defines the shape of the form values.
export interface SupportFormValues {
  email: string,
  firstName: string,
  lastName: string,
  accountId?: number,
  category: number,
  request: string
}

// Defines the shape of the props available for the component.
export interface SupportFormProps {
  title?: string | React.ElementType,
  message?: string,
  initialPropValues?: SupportFormValues
}

// Wraps the SupportForm component using the withFormik HOC.
export const SupportForm = withFormik<SupportFormProps, SupportFormValues>({
  // Transforms outer props into form values.
  mapPropsToValues: ({initialPropValues}) => {
    return {
      email: initialPropValues && initialPropValues.email || '',
      firstName: initialPropValues && initialPropValues.firstName || '',
      lastName: initialPropValues && initialPropValues.lastName || '',
      accountId: initialPropValues && initialPropValues.accountId || undefined,
      category: initialPropValues && initialPropValues.category || 1,
      request: initialPropValues && initialPropValues.request || '',
    };
  },

  // Validates form values using Yup validation schema.
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('E-mail address is not a valid format.')
      .required('E-mail address is required.'),
    firstName: Yup.string()
      .required('First name field is required.'),
    lastName: Yup.string()
      .required('Last name field is required.'),
    accountId: Yup.string(),
    category: Yup.string()
      .required('Category field is required.'),
    request: Yup.string()
      .required('Request message field is required.'),
  }),

  // Processes submit behavior.
  handleSubmit: (values: SupportFormValues, actions: FormikActions<SupportFormValues>) => {
    setTimeout(() => {
      console.log('Form Submitted');
      console.log('Form Actions', actions);
      console.log('Form Values', JSON.stringify(values));
      actions.setSubmitting(false);

      // @todo - mschaal - Integrate logic to validate submission (e.g. OAPI request).
      if (true) {
        actions.setStatus( { success: 'Form submitted successfully.'}); 
      } else {
        actions.setStatus( { failure: 'Form failed to submit.'});
      }
    }, 250);
  }
})(InnerSupportForm);