import { WebApplication } from '@nexvortex/framework';
import ReactExtension from '@nexvortex/extension-react';
import ReactRouterDomExtension from '@nexvortex/extension-react-router-dom';
import ReactDomExtension from '@nexvortex/extension-react-dom';
import * as React from 'react';

import OApiExtension from './extensions/OApiExtension';
import ReactOApiExtension from './extensions/ReactOApiExtension';
import config from './config.exports';

import './index.scss';
import App from './App';

new WebApplication({
  container: document.getElementById('root'),
  extensions: [
    new OApiExtension({
      baseUrl: config.oapiBaseUrl,
      clientId: config.oapiClientId,
      clientSecret: config.oapiClientSecret
    }),
    new ReactExtension(<App/>),
    new ReactOApiExtension(),
    new ReactRouterDomExtension(),
    new ReactDomExtension()
  ]
});
