import { LoginPageState, ReferrerOpts } from '.';

// Defines Reducer Action types.
export type LoginPageReferrerOptType = {
  type: 'SET_REFERRER_OPTS',
  value: ReferrerOpts
}

export type LoginPageFormErrorAction = {
  type: 'SET_FORM_ERROR',
  value: string
}

export type LoginPageFormSuccessAction = {
  type: 'SET_FORM_SUCCESS',
  value: string
}

export type LoginPageActionTypes = 
  | LoginPageReferrerOptType
  | LoginPageFormErrorAction
  | LoginPageFormSuccessAction
  | { type: string; value: any};

// Defines Reducer.
export const reducer = (state: LoginPageState, action: LoginPageActionTypes) => {
  switch (action.type) {
  case 'SET_REFERRER_OPTS': {
    const { referrer, ...prevState} = state;
    const { value } = action as LoginPageReferrerOptType;
    return {
      ...prevState,
      referrer: value,
    };
  }
  case 'SET_FORM_ERROR': {
    const { notification, ...prevState } = state;
    const { value } = action as LoginPageFormErrorAction;
    return {
      ...prevState,
      notification: {
        type: 'error',
        message: value
      }
    };
  }
  case 'SET_FORM_SUCCESS': {
    const { notification, ...prevState } = state;
    const { value } = action as LoginPageFormSuccessAction;
    return {
      ...prevState,
      notification: {
        type: 'info',
        message: value
      }
    };
  }
  default: 
    return state;
  }
};