import * as React from 'react';
import { Form, FormikProps } from 'formik';
import { FormGroup, Button, Spinner } from 'reactstrap';

// Import local Components.
import { SupportFormProps, SupportFormValues } from 'src/components/features/Forms/SupportForm';
import { EmailAddressFieldGroup, FirstNameFieldGroup, LastNameFieldGroup, FormSubmissionMessage } from 'src/components/shared/Form';
import { RequestFieldGroup } from '../Fields';

// Defines Form component to be wrapped by by the Formik HOC.
const InnerSupportForm = (
  props: SupportFormProps & FormikProps<SupportFormValues>
) => {
  const { isSubmitting, status, message, title, } = props;
  
  return (
    <Form className="form form--support-form">
      {title && <h3 className="form__title">{title}</h3>}

      <FormSubmissionMessage />

      <EmailAddressFieldGroup />
      <FirstNameFieldGroup />
      <LastNameFieldGroup />
      <RequestFieldGroup />

      <FormGroup className="form-group--submit-handlers">
        <Button type="submit" color="primary" size="lg" block={true} active={isSubmitting}>
          { isSubmitting ? <Spinner color="white" size="sm" /> : 'Submit Request' }
        </Button>
      </FormGroup>
    </Form>
  );
};

// Exports named default.
export default InnerSupportForm;
