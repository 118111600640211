import { AxiosInstance } from 'axios';
import { ApiResponse, DateTime } from './types';

export interface SdnPathConfiguration {
	nvsbPathConfigRecid: number,
	createDateTime: DateTime,
	updateDateTime: DateTime,
	status: string, // 'active' | ?
	name: string,
	description: string,
	pathConfigVersion: number,
	pathConfig: {
		paths: {
			nVE: string, // 'A' | 'B' | 'C' | 'D' (| ... ?)
			SDNGW: string // Same as nVE
		}[],
		interfaceCosts: {
			interface: string, // 'A' | 'B' | 'C' | 'D' (| ... ?)
			cost: number // >= 5 means LTE
		}[],
		defaultExpiration: number,
		costIntervals: {
			pathCost: number,
			time: number
		}[],
		lossThreshold: number, // Ratio (0. - 1.)
		rtdelayThreshold: number,
		topStateInterval: number,
		evaluationInterval: number,
		expirationInterval: number,
		icmpPingTopStateInterval: number
	}
}

export class Sdn {
	private http: AxiosInstance;
	public constructor(http: AxiosInstance) {
	  this.http = http;
	}

	public async pathConfiguration() {
	  try {
	    const response = await this.http
	      .get<ApiResponse<SdnPathConfiguration>>('/v2/mHV/SDN/Paths/Configuration');
	    return response.data;
	  }
	  catch (error) {
	    throw error;
	  }
	}
}

export default Sdn;
