import Axios, { AxiosInstance } from 'axios';
import { AuthClient } from './AuthClient';
import { EdgeDevice } from './EdgeDevice';
import { Sdn } from './Sdn';
import { User } from './User';

export interface OApiClientConfig {
	baseUrl: string,
	clientId: string,
	clientSecret: string
}

export interface ApiResponse<T> {
	data: T[],
	meta?: {
		pagination: {
			total: number,
			count: number,
			per_page: number,
			current_page: number,
			total_pages: number,
			links: string[]
		}
	}
}

export class OApiClient {
	private baseUrl: string;

	public http: AxiosInstance;
	public auth: AuthClient;
	public edgeDevice: EdgeDevice;
	public sdn: Sdn;
	public user: User;

	constructor({ baseUrl, clientId, clientSecret }: OApiClientConfig) {
	  this.baseUrl = baseUrl;

	  this.http = Axios.create({
	    baseURL: this.baseUrl,
	    headers: {
	      Accept: 'application/json',
	      'Content-Type': 'application/json'
	    },
	    timeout: 10000
	  });

	  this.auth = new AuthClient({
	    clientId,
	    clientSecret,
	    httpClient: this.http
	  });

	  this.edgeDevice = new EdgeDevice(this.http);
	  this.sdn = new Sdn(this.http);
	  this.user = new User(this.http);
	}
}

export default OApiClient;
