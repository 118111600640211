import { Context, WebExtension } from '@nexvortex/framework';
import OApiClient, { OApiClientConfig } from '../oapi';

export class OApiExtension implements WebExtension {
	private oapi: OApiClient;

	public provides = ['oapi'];
	public uses = [];
	public needs = [];
	public excludes = [];
	public first = true;
	public last = false;

	constructor(config: OApiClientConfig) {
	  this.oapi = new OApiClient(config);
	}

	public start(context: Context) {
	  context.set('oapi', this.oapi);
	}
}

export default OApiExtension;
