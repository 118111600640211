import { AxiosInstance, AxiosResponse } from 'axios';

export type OAuth2Token = {
	token_type: string,
	expires_in: number,
	access_token: string,
	refresh_token: string
};

export interface AuthClientConfiguration {
	clientId: string,
	clientSecret: string,
	httpClient: AxiosInstance
}

export class AuthClient {
	private clientId: string;
	private clientSecret: string;
	private http: AxiosInstance;

	private token: OAuth2Token | null = null;

	constructor({
	  clientId,
	  clientSecret,
	  httpClient
	}: AuthClientConfiguration) {
	  this.clientId = clientId;
	  this.clientSecret = clientSecret;
	  this.http = httpClient;
	}

	public get authenticated() { return this.token ? true : false; }

	/**
	 * Sets the internal OAuth2 token, if valid
	 *
	 * This should only be used for setting up the initial authentication state
	 * if there's an OAuth2 token being read from storage, such as `localStorage`,
	 * or the environment, e.g. from the query string.
	 */
	public hydrate(token: OAuth2Token) {
	  // TODO: Validate!
	  console.warn('TODO: Validated token used for hydration');
	  // - Check for all properties
	  // - Check if token is stale, refresh if needed
	  this.token = token;
	}

	/**
	 * Requests a new token using the given user credentials and the required
	 * configuration including client credentials.
	 */
	public authenticate = async (username: string, password: string, clientId: string|null = null, clientSecret: string|null = null) => {
	  if (!username) throw new Error('Username');
	  if (!password) throw new Error('Password');

	  const response = await this.http.post<OAuth2Token>(
	    '/access_code',
	    {
	      username,
	      password,
	      client_id: clientId ? clientId : this.clientId,
	      client_secret: clientSecret ? clientSecret : this.clientSecret,
	      grant_type: 'password'
	    }
	  );

	  const result = response.data;
	  this.token = result;

	  // Authenticated, add interceptors to update authentication

	  this.http.interceptors.request.use(config => {
	    if (!this.token) return config;

	    return ({
	      ...config,
	      headers: {
	        ...config.headers,
	        'Authorization': `Bearer ${this.token.access_token}`
	      }
	    });
	  });

	  this.http.interceptors.response.use(
	    response => response,
	    error => {
	      if (error.response) {
	        const response: AxiosResponse = error.response;
	        if (response.status === 401) {
	          // Attempt a refresh, otherwise revoke
	          this.refresh().catch(() => {
	            this.token = null;
	          });
	          this.token = null;
	          return;
	        }
	      }

	      throw error;
	    }
	  );

	  return result;
	};

	/**
	 * Requests an access token to be refreshed using the given token, provided during
	 * authentication, and the required configuration including client credentials.
	 */
	public refresh = async () => {
	  if (!this.token) throw new Error('Require authentication');

	  const response = await this.http.post<OAuth2Token>(
	    '/access_code',
	    {
	      refresh_token: this.token.refresh_token,
	      client_id: this.clientId,
	      client_secret: this.clientSecret,
	      grant_type: 'refresh_token'
	    }
	  );

	  const result = response.data;
	  this.token = result;

	  return result;
	};

	public revoke = () => {
	  this.token = null;
	};
}