import { AxiosInstance } from 'axios';
import { ApiResponse } from './types';

export interface UserSession {
	contactId: number,
	createDateTime: string,
	updateDateTime: string,
	flagError: null,
	account: {
		accountId: number,
		userId: string,
		userDomain: string
	},
	userId: string,
	userDomain: string,
	userPassword: string,
	userPasswordNew: string,
	nameFirst: string,
	nameLast: string,
	title: string,
	department: string,
	contactRoleDefRecid: number,
	verifyCode: string,
	primaryContact: number,
	type: string,
	status: string,
	securitySettings: number,
	securityQuestion1: string,
	securityAnswer1: string,
	securityQuestion2: string,
	securityAnswer2: string,
	tmpMenuRecid: number,
	channelAccess: number,
	visibility: string,
	accessWeb: number,
	accessEmail: number,
	nVIM: number,
	jobFunction: string,
	gender: string,
	skill: string,
	influence: string,
	nVLikability: string,
	notes: string,
	passwordSalt: string,
	recoveryMobileId: number,
	recoveryExpirationDatetime: null,
	phones: {
		data: {
			phoneId: number,
			phone: string,
			phoneType: string
		}[]
	}
};

export class User {
	private http: AxiosInstance;
	public constructor(http: AxiosInstance) {
	  this.http = http;
	}

	public async session() {
	  try {
	    const response = await this.http.get<ApiResponse<UserSession>>('/v2/User/Session');
	    return response.data;
	  }
	  catch (error) {
	    throw error;
	  }
	}
}

export default User;
