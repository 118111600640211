import * as React from 'react';

// Import local Components.
import { Link as RouterLink } from 'react-router-dom';
import { Nav, NavItem, Button } from 'reactstrap';

// Import local Assets.
import './styles.scss';

export interface ErrorPageProps {
	title?: string,
	summary?: string,
	children?: React.ReactNode,
	component?: React.ReactNode,
	render?: React.ReactNode,
	referrer?: string,
	userId?: number,
	accountId?: number
}

export const ErrorPage: React.SFC<
ErrorPageProps & React.HTMLAttributes<HTMLDivElement>
> = ({ title, summary, className, children, component, render, ...props }) => {
  const classNames = ['page', 'page--error', className].join(' ');

  return (
    <section className={classNames} {...props}>
      <h2 className="page__title">{title}</h2>
      <main className="error-messages">
        <p className="error-messages__summary">{summary}</p>
        <Nav vertical={false} className="nav--error-page-navigation">
          <NavItem>
            <Button size="lg" color="primary" tag={RouterLink} to="/">
							Take Me Back Home
            </Button>
          </NavItem>
          <NavItem>
            <Button
              size="lg"
              color="white"
              outline={true}
              tag={RouterLink}
              to="/insecure/account/support"
            >
							Contact Support
            </Button>
          </NavItem>
        </Nav>
      </main>
    </section>
  );
};
