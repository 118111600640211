import * as React from 'react';
import { Form, FormikProps } from 'formik';
import { FormGroup, Button, Spinner } from 'reactstrap';

// Import local Components.
import { RecoveryFormProps, RecoveryFormValues} from 'src/components/features/Forms/RecoveryForm';
import { FormMessagesGroup } from 'src/components/features/Forms/RecoveryForm/Fields';
import { EmailAddressFieldGroup } from 'src/components/shared/Form';

// Defines Form component to be wrapped by by the Formik HOC.
const InnerRecoveryForm: React.SFC<RecoveryFormProps & FormikProps<RecoveryFormValues>> = (props) => {
  const { isSubmitting, message, title } = props;
  
  return (
    <Form className="form form--recovery-form">
      {title && <h3 className="form__title">{title}</h3>}

      {message && (
        <FormMessagesGroup message={message} />
      )}

      <EmailAddressFieldGroup />

      <FormGroup className="form-group--submit-handlers">
        <Button color="primary" size="lg" block={true} active={isSubmitting}>
          { isSubmitting ? <Spinner color="white" size="sm" /> : 'Recover My Account' }
        </Button>
      </FormGroup>
    </Form>
  );
};

// Exports named default.
export default InnerRecoveryForm;
