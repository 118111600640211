import * as React from 'react';
import { Field, FieldProps } from 'formik';
import { FormGroup, Label, Input } from 'reactstrap';

// Import local Components.
import { FormFieldError, FormFieldHelptext } from 'src/components/shared/Form';
 
const PasswordGroup: React.SFC<{}> = () => {
  return (
    <FormGroup className="form-group--credentials">
      <Field
        type="password"
        name="password"
        id="password"
        render={({ form, field }: FieldProps) => {
          return (
              <>
                <Label for={field.name}>{field.name}</Label>

                <FormFieldError name={field.name} />

                <Input
                	type="password"
                	bsSize="lg"
                	invalid={!!(form.touched.password && form.errors.password)}
                	{...field}
                />
                
                <FormFieldHelptext
                	text="Enter your password."
                	a11y="Please enter your the password required for your login
                  session."
                />
              </>
          );
        }}
      />
    </FormGroup>
  );
};
 
export default PasswordGroup;